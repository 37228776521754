import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { ForgetPasswordComponent } from "../../components/AuthComponent/ForgetPassword"

const ForgetPassword = () => {
  return (
    <Layout noFooter>
      <SEO title="Lupa Password" />
      <ForgetPasswordComponent />
    </Layout>
  )
}

export default ForgetPassword
